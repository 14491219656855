.node-type-product{

  .categoriesCSV > a{
    font-size:1rem;
    color:white;
    background-color:#373737;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    border-radius: 5px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
.productTitle > a{
  font-size:1.8rem;
  color:$black;
}


  .productCode{
    display: inline-block;
    font-weight: bold;
    font-size: 1rem;
  }

  .productCodeValue{
    font-weight: normal;
    font-size: 0.9rem;

  }
  .RequiredQuantity{
    font-weight: bold;
    display: inline-block;
    font-size: 1rem;
  }
  .RequiredQuantityValue{
    font-weight: normal;
    font-size: 1.1rem;
  }
  .Package{
    font-weight: bold;
    display: inline-block;
    font-size: 1rem;

  }
  .PackageValue{
    font-weight: normal;
    font-size: 1.1rem;

  }

}