.logo-container{
  text-align: center;

  &-left{
    display: none;

    @include breakpoint(medium only){
      text-align: left;
      display: block;
    }

    @include breakpoint(small only){
      text-align: left;
      display: block;
    }
  }

  &-right{
    @include breakpoint(medium only){
      text-align: right;
    }

    @include breakpoint(small only){
      text-align: right;
    }
  }

  @include breakpoint(large){
    text-align: left;
  }
}

.menu.medium-horizontal {

  @include breakpoint(medium only) {
    align-items: center;
    justify-content: center;
  }

}


.block-system-main-menu {
   width: 100%;
   padding-top: 10px;

   li a {
     color:$black;

     &.active-trail {
      color: $primary-color;
      font-weight: bold;
     }
   }

   .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
     display: block;
     width: 0;
     height: 0;
     border: inset 6px;
     content: '';
     border-bottom-width: 0;
     border-top-style: solid;
     border-color: $black transparent transparent;
     right: 5px;
     margin-top: -3px;
   }
 }

 .lang-select img {
   max-width: 120px;
 }
