.view-list-of-product-types-block  {

  margin-top:20px;


  .view-header{
    display: inline-block;
    float:left;
    text-transform: uppercase;
    font-size:1.5rem;
    color: #8F7229;
  }


  .view-content{
    display: inline-block;
    float:left;


    ul li {
      list-style-type: none;
      a {
        color:$black;
      }
    }
  }



}