.vocabulary-applications{
  $expand-column-transition: all 0.5s ease-in-out;
  $expand-column-background-color: #2c3840;
  $expand-column-hover-width: 80%;
  $expand-column-fluid: true;


  .image-slideshow{
    padding-bottom:10px;
  }


  .expand-column-wrapper {

    // background-color:red;
    height: 50vh;
    @include breakpoint(small only) {
      height: 100vh;
    }


    @if $expand-column-fluid == 'true' {
      max-width: none;
    } @else {
      max-wdtih: $global-width;
    }

    .column {
      padding: 1rem;
      transition: $expand-column-transition;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        flex-basis: $expand-column-hover-width;
      }

      &:hover {
        .expand-column-content {
          opacity: 1;
        }
      }
    }

    .expand-column-content {
      color: $white;
      font-weight: bold;
      opacity: 0;
      transition: $expand-column-transition;
    }

    @include breakpoint(small only) {
      flex-direction: column;
    }
  }

  .mainHeader{
    /*font-size: 1.2rem;*/
  }

  .MainHeaderImage > img{
    object-font:cover;
    padding-bottom:20px;
    margin:0 auto;
    min-width:20%;
    display: block;
  }

  .secHeader{
    display: inline-block;
    background-color: #B9A05E;
    color:$white;
    vertical-align: middle;
    margin: 1rem 0 1rem 0;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }



  .gridImages{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;/*flex-start | flex-end | center | space-between | space-around;*/
    margin:-10px 0 0 -10px;
    padding-bottom: 40px;
  }
  .griddedImage{
    display: inline-block;
    margin:10px 0 0 10px;
    flex-grow: 1;
    width: calc(100% * (1/3) - 10px - 1px);
    background-color: #CCC8D5;
    align-self: auto;/*auto | flex-start | flex-end | center | baseline | stretch;*/
  }
  .griddedImage > a > img {
    object-fit:cover;
    width:100%;
    min-height:250px;
    vertical-align: middle;
  }

}
