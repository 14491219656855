
#map {
  height: 400px;

  @include breakpoint(small) {
    height: 200px;
  }
  .marker-style {
    background-color: #1c1c1c;
    color: #fff;
    font-family: Arial,sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding: 10px;
    margin-top: -80px!important;
    margin-left: 0!important;
    overflow: visible!important;

    &:after {
    content: "";
    border-style: solid;
    border-width: 18px 18px 0 0;
    border-color: #1c1c1c transparent transparent;
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    bottom: -18px;
    }
  }
}

