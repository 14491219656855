.node-news-item {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  line-height: 100%;
  color: black; //$blue;

  width:80%;
  margin:0 auto;
  font-size: 17px;
  line-height: 100%;
  color: #8A8A8A;
  opacity: 1.00;

  .field-type-file {
    .field-label{
      display: none;
    }

    .file{
      display: block;
      width: 100%;
    }
  }

  .news-item-title{
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 100%;
    text-transform: capitalize;
    color: black; //$blue;

    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;

    //padding-top:20px;
    padding-bottom:20px;
  }

  .news-item-date{
    line-height: 100%;
    text-transform: capitalize;
    /* 02 dec 2017 */
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 100%;
    color: #ac7728;
    // padding-top:20px;
    //padding-bottom:20px;
  }


  .news-item-body{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;

    color: #828282;
    // padding-top:20px;
    padding-bottom:20px;
  }

  .news-item-share{
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: black; // $blue;
  }

  .news-item-sharebtn{
    padding-left:20px;
    height:50px;
  }

  .news-files{
    margin-bottom: 20px;
  }



  .news-item-share {
    text-align: left;
    @include breakpoint(small down) {
      text-align: center;
    }
    img {
      display: inline-block;
    }
    .facebook {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
    h3 {
      text-transform: uppercase;
      font-size: 2rem;
    }
    .instagram {
      width: 2rem;
      height: 3rem;
    }
    svg {
      fill: black; //$blue;
      width: 2rem;
      height: 2rem;
    }
  }
}