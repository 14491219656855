.block-prev-next-0{


  ul.prev-next-links{

    list-style:none;
    display: flex;
     flex-wrap: nowrap;
     align-items: center;
    width: 100%;
    margin: 0 auto;

    li:first-child{
      min-width: 200px;
      padding-left: 20px;
      display: inline-block;
      flex: 1 2 auto;
    }

  }
}