.view-products-listing-using-facets{
  .views-row{
  padding: 2rem;
    /* border: solid 1px #ccc; */
    margin-bottom: 1rem;
    background: #F5F5F5;  }

  .product_title{
    font-size:1.5rem;
    font-weight:bold;
    a{
    color: $black;
    }
  }

  .product_description{
    font-size:1rem;
    text-align:justify;
  }


  .product_description{
    font-size:1rem;
  }

  .product_footer{
    .mainText a{
     color: #8F7229;
      font-size:1.3rem;
      font-weight:bold;
      }

  }

}

