.block-webform-client-block-8{
  width:70%;
  float:right;
  padding-top:2rem;

  .block-webform{
  }
  .contextual-links-region{
    display:inline-flex;

  }
.block-title{
   font-size: 1.3rem;
    text-transform: uppercase;
    display: inline-block;
    color: #444;
    font-weight: 700;
  }

  .form-email {
    display:inline-block;
    margin-right: 0.5em;
  }
  .contextual-links-wrapper{
    display:inline-block;

  }
  .webform-client-form{
    display:inline-block;
  }
  //:nth-child(1)
  .webform-client-form div{
    width:100%;
    display: flex;
  }
  .form-item, .form-actions{
    margin-top:0px;
    margin-bottom:0px;
  }
  .webform-submit{
    display:inline-block;
    margin-right: 0.5em;
    background-color: #8F7229;
  }

  .form-item{
    display:inline-block;
  }


}
