.vocabulary-product-types{

  .mainHeader{
    /*font-size: 1.2rem;*/
  }

  .MainHeaderImage > img{
    object-font:cover;
    width:100%;
  }

  .secHeader{
    display: inline-block;
    background-color: #B9A05E;
    color:$white;
    vertical-align: middle;
    margin: 1rem 0 1rem 0;
    font-size: 1.1rem;
    line-height: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }



  .gridImages{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;/*flex-start | flex-end | center | space-between | space-around;*/
    margin:-10px 0 0 -10px;
    padding-bottom: 40px;
  }
  .griddedImage{
    display: inline-block;
    margin:10px 0 0 10px;
    flex-grow: 1;
    width: calc(100% * (1/3) - 10px - 1px);
    background-color: #CCC8D5;
    align-self: auto;/*auto | flex-start | flex-end | center | baseline | stretch;*/
  }
  .griddedImage > a > img {
    object-fit:cover;
    width:100%;
    min-height:250px;
    vertical-align: middle;
  }

}
