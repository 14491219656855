.abovefooter{
  background: #F5F5F5;
  color: $white;
  padding: 2rem 0;
  margin-top: 30px;


  .block-block-8{
    padding-top:50px;


    ul{
      color: $black;
      list-style-type:none;
      text-transform: uppercase;
      li{
        a,a:visited,a:active,a:hover{
          font-family:"Noto Sans";
          font-size:16px;
          color: black;
        }
      }
    }
  }

  .block-webform-client-block-8{
    width:100%;

    @include breakpoint(medium up){
      padding-top:40%;
    }

    .webform-submit{
      color:$black;
      background-color: #dcdcdc;
    }
  }

}

.footer {
  background: $white;
  color: $black;
  padding: 2rem 0 0;
  font-size:0.8rem;

  .social-icons{
    svg {
      display: inline-block;
      height: 35px;
      width: 35px;
      vertical-align:middle;
    }
  }
  &__social {
    text-align: center;

    svg {
      fill: $white;
      width: 3rem;
      height: 3rem;
    }
  }

  &__logo {
    text-align: center;

    svg {
      fill: $white;
      width: 10rem;
      height: 4rem;
    }

    h4 {
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  &__location {
    text-align: center;

    h4 {
      font-size: 1rem;
    }
  }

  > .row {
    margin-bottom: 1rem;
  }

  &__menu {
    a {
      color: $white;
    }

    @include breakpoint(small only) {
      ul {
        flex-direction: column;
      }
    }
  }

  &__bottom {
    background-color: $white;
    color: $black;
    text-align: center;
    padding-top: 0.5rem;

    @include breakpoint(small only) {
      font-size: 0.7rem;
    }
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }

}

