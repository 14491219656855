.block-block-11{
  padding-top:40px;
 p {
   font-size:0.8rem;
   padding-top:20px;
   text-align: center;
   vertical-align: middle;
 }

  a.bracketed-singleline-link{
    width:110%;
  }
}