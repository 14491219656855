

.product {


  text-align: center;
  margin-bottom: 20px;

  @include breakpoint(small ) {
  //  @include flex-grid-size(12);
  }
  //
  @include breakpoint( medium) {
    min-height: 300px;
  //  @include flex-grid-size(6);
  }
  //
  @include breakpoint(large medium) {
  //  @include flex-grid-size(3);
  }

  .product-border{
    padding: 0.9375rem;
    border: 1px solid #cdcdcd;
  }



  .title{
    font-size: 20px;
    min-height: 90px;
    color:#baa05e;
  }



}
