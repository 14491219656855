.customHeader{
  background-color: #E7E6EB;
  display: inline-block;
  width:100%;
  min-height:2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: $black;

  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 7px;

/*  padding-left: 20px;*/
}

.customCol img{
  width:100%;
  object-fit:cover;
}

.drilldown a{
  text-align: center;
}

/* menu  item on small devices was a different color */
.is-drilldown-submenu-parent > a::after{
  border-color: transparent transparent transparent #0a0a0a;
}
.js-drilldown-back > a::before{
  border-color: transparent #0a0a0a transparent transparent;
}