 $about-bg-color: #E9E5E0;

 .about {
   background-color: $about-bg-color;

   h2 {
     font-weight: 700;
     text-transform: uppercase;

     @include breakpoint(small) {
       padding-top: 2rem;
     }

     small {
       color: $black;

       @include breakpoint(small) {
         font-size: 2rem;
       }
     }
   }

   &__info {
     text-transform: uppercase;
   }

   &__title {
     font-weight: 300;
     text-transform: uppercase;
   }

   &__subtitle {
     text-transform: uppercase;
     color: $light-gray;
   }

   &__text {
     color: $light-gray;
   }

   &--item {
     padding-bottom: 2rem;

     &--inverse {
       flex-direction: row-reverse;
       padding-bottom: 2rem;
     }
   }
   .views-row-even {
     flex-direction: row-reverse;
     padding-bottom: 2rem;
   }
 }
