
.navbar-brand2{
  img{
    max-height:74px;
    //float:right;
  }
}

.block-block-2{
  font-size:0.9rem;
}

// http://nicolasgallagher.com/multiple-backgrounds-and-borders-with-css2/demo/backgrounds.html
a.bracketed-doubleline-link:before {
  content: url('/sites/all/themes/custom/foundation/mr-impossible-body.png');
  display: block;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100px;
  border-right: 0px solid transparent;
  text-align: center;
  background: url('/sites/all/themes/custom/foundation/mr-impossible-left.png') no-repeat 0 39px;
}
a.bracketed-doubleline-link:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0px;
  right: 0;
  width: 30px;
  height: 55px;
  background: url('/sites/all/themes/custom/foundation/mr-impossible-right.png') no-repeat 100% 0;
}

a.bracketed-doubleline-link{
  display: block;
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color:black;



  width: 90%;
  line-height: inherit;
  // background-color: red;
  text-decoration: none;
  cursor: pointer;
  // width:100px;
  word-wrap: break-word;
  margin: 0 auto;
}


/*
// bracketed-singleline-link moto
a.bracketed-singleline-link:after{
  content:"]";
  font-size: 3rem;
  font-weight: bolder;
  color: #8F7229;
  position: relative;
  left: 35px;
  top: 0.7rem;
}
a.bracketed-singleline-link:before{
  content:"[";
  font-size: 3rem;
  font-weight: bolder;
  color: #8F7229;

  position: relative;
  right: 35px;
  top: 0.7rem;
}
a.bracketed-singleline-link{
  line-height: inherit;
  color:black;
  text-align: center;
  font-size: 1rem;
  // background-color: red;
  text-decoration: none;
  cursor: pointer;

  // width:100px;
  word-wrap: break-word;

  margin: 0 auto;
  display: block;

}
*/

///////////////////////////////////
a.bracketed-doubleline-link:hover::before,
a.bracketed-doubleline-link:focus::before {
  // transform: scale(0.7);
  // opacity: 0;
}
a.bracketed-doubleline-link:hover::after,
a.bracketed-doubleline-link:focus::after {
  // transform: scale(0.7);
  // opacity: 0;
}
/*
a.bracketed-doubleline-link:after{
  content:"]";
  font-size: 4rem;
  font-weight: bolder;
  color: #8F7229;

  position: relative;
  left: 20%;
  top: -4.5rem;
  display: block;
  height: 0;
  clear: both;
  // transition: transform 0.3s, opacity 0.3s;
  // transform: scale(1);
}
a.bracketed-doubleline-link:before{
  content:"[";
  font-size: 4rem;
  font-weight: bolder;
  color: #8F7229;
  position: relative;
  right:20%;
  top: -1.5rem;
  display: block;
  height: 0;
  clear: both;
  // transition: transform 0.3s, opacity 0.3s;
  // transform: scale(1);
}
a.bracketed-doubleline-link{
  line-height: inherit;
  color:black;
  text-align: center;
  font-size: 1rem;
  // background-color: lightcoral;
  // text-decoration: none;
  cursor: pointer;

  margin-top: 2rem;
  display: block;
}
*/




// http://nicolasgallagher.com/multiple-backgrounds-and-borders-with-css2/demo/backgrounds.html
a.bracketed-singleline-link:before {
  content: url('/sites/all/themes/custom/foundation/mr-impossible-body.png');
  display: block;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100px;
  border-right: 0px solid transparent;
  text-align: center;
  background: url('/sites/all/themes/custom/foundation/mr-impossible-left.png') no-repeat 0 39px;
  top:-40px;
}
a.bracketed-singleline-link:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0px;
  right: 0;
  width: 30px;
  height: 55px;
  background: url('/sites/all/themes/custom/foundation/mr-impossible-right.png') no-repeat 100% 0;
  top: 0px;
}

a.bracketed-singleline-link{
  display: block;
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color:black;

  width: 90%;
  line-height: inherit;
  // background-color: red;
  text-decoration: none;
  cursor: pointer;
  // width:100px;
  word-wrap: break-word;
  margin: 0 auto;
  padding-top:20px;
}




.pulse {
  animation-name: pulse_animation;
  animation-duration: 5000ms;
  transform-origin:50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

.part-seperate-background{
  background-color: #eadec4;
  padding:20px 20px 20px 20px;
  p {
    margin-bottom: 0px;
    font-size:0.8rem;
    text-align: justify;
  }
  a{
    font-size:0.8rem;
    font-weight: bolder;
  }
}

.button-on-top-of-image{
  position: relative;
  width: 100%; /* for IE 6 */
  .link-on-top-of-image {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    font-weight: bolder;
  }
}

.view-efarmoges-print-list{
  .slick-arrow {
    z-index: 111;

    &:before {
      font-size: 60px;
      color: #baa05e;
    }
  }

  @include breakpoint (small ){
    .slick-next {
      right: 10px;
    }

    .slick-prev {
      left: -30px;
    }
  }

  @include breakpoint (medium ){
    .slick-next {
      right: 40px;
    }

    .slick-prev {
      left: 0px;
    }
  }

  @include breakpoint (large){
    .slick-next {
      right: 120px;
    }

    .slick-prev {
      left: 0px;
    }
  }

}

.view-efarmoges-print-list  .views-row-1 .image-wrapper img{
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  height: 400px;

  @include breakpoint(medium){
    height:700px;
  }
}


.l-main{
 @media screen and (max-width: 40em) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }


.field-name-field-gallery{

  margin-top: 1.5rem;
  text-align: center;

  .colorbox{
    display: inline-block;
    margin: 5px;
  }
}

.navbar-brand img{
  @include breakpoint (large){
    max-width: none;
  }
}

/*----------PAGINATION---------------*/
.pagination-centered{
  display: none;
}

.field-name-title-field {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
}


.front .node-page .field-name-title-field {
  display: none;
}

.views-field-description-i18n{
      /* text-align: center; */
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-bottom: 20px;
}


.views-field.views-field-name-field-et{
  width: 100%;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
}