@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;

@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "zurb_foundation";
// Components
@import "components/navigation";
@import "components/building-blocks/off-canvas-full-screen";
@import "components/hero";
@import "components/about";
@import "components/map";
@import "components/newsletter";
@import "components/footer";
@import "components/reservations";
@import "components/gallery";

@import "components/tabs";


@import "components/otherGeneric";

@import "components/block_menu";
@import "components/block_languages";
@import "components/block_breadcrumbs";

@import "components/block_listOfApplications";
@import "components/block_listOfProductTypes";
@import "components/block_FrontPageProducttypes";
@import "components/block_FrontPageApplications";

@import "components/block_facetapi";
@import "components/views_products_listing_using_facets";
@import "components/views_application_listing_using_facets";


@import "components/node_type_product";
@import "components/block_newsletter";
@import "components/view_exhibitions";
@import "components/taxonomy_term_vocabulary_applications";
@import "components/taxonomy_term_vocabulary_producttypes";


@import 'components/building-blocks/social-icon-button';
@import 'components/building-blocks/flip-card';

@import 'components/building-blocks/marketing-site-content-section';
@import 'components/building-blocks/marketing-site-features';

@import 'components/faq/view-faq.scss';

@import 'components/shame';

@import 'components/view-applications-list-view';
@import 'components/block-contact-us';
@import 'components/block-block-address';
@import 'components/block-block-introblocks';
@import 'components/view-productsofaplication';

@import 'components/block_prev_next';
@import 'components/extra';
// news
@import "components/view_news_listing_page";
@import "components/node_news_item";

body{
  overflow-x: hidden;
}

.item-list .pager {
  margin-top: 2rem;
  li {
    display: inline-flex;
  }
}
