.view-exhibitions{

  .view-content{

    display: block;
    flex-wrap: wrap;

    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

    justify-content: space-around;/*flex-start | flex-end | center | space-between | space-around;*/
    margin:-10px 0 0 -10px;
    padding-bottom: 40px;

    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
    max-width: none;
    flex-flow: row wrap;
    display: flex;



  }


  .ExhibitionItem{
      display: inline-block;
      font-size: 1.4em !important;
      //max-width: none;
      //flex: none;
      //width: 100%;
      //margin-left: 5px;
      //margin-right: 5px;
      margin-bottom: 40px;

    }


// .divName a{
//   margin-left:10px;

// }
  .divName  a,a:hover, a:focus{
    font-size: 16px;
    font-weight: bolder;
    color: #0a0a0a;
    line-height: 23px !important;
    display: block;
    font-family: sans-serif;
  }

  .date-display-single {
    font-size: 1rem;
    font-weight: normal;
    color: #0a0a0a;
    margin-bottom: -8px;
    display: block;

  } 


  .invitationsLink{
    float:right;
    margin-right:10px;

    .file{
      margin: 0 0 1rem 0;

      -webkit-appearance: none;
      border: 1px solid transparent;
      background-color: $white;
      border-radius: 25px;
      font-size: 1rem;
      line-height: 1;
      text-align: center;
      padding-top: 10px;
      padding-bottom:10px;
      padding-left: 10px;
      padding-right: 10px;


       a,a:hover, a:focus{
        color:black;
        font-size:0.9em;

      }

      .file-size{
        display: none;
      }
    }
  }
  .divPhoto > a > img {
    object-fit:cover;
    min-height:250px;
    width:100%;
    vertical-align: middle;
    max-width: 100%;
  }

}