// sample
// http://foundation.zurb.com/building-blocks/blocks/masonry-css.html
.view-news-listing-page{


  .view-content{
    //row small-up-1 medium-up-2 large-up-3

    h3,h3 span{
      display: block;
      width: 100%;
      margin-top: 20px;
    }

    @include flex-grid-row;
    @include breakpoint(small){
      @include flex-grid-layout($n:1);
    }

    @include breakpoint(medium){
      @include flex-grid-layout($n:2);
    }

    @include breakpoint(large){
      @include flex-grid-layout($n:3);
    }

    .masonry-css-item {
      break-inside: avoid;
      padding-top:10px;
      padding-bottom:10px;
      padding-left:20px;
      padding-right:20px;
    }



    .view-news-listing-page .view-content .views-row-even{

    }

    .views-row-even > masonry-css-item{
      margin-top:20px;

    }
    .views-row-odd > .masonry-css-item {
      margin-top:120px;

    }
    
    .newitem-photo{
        img{
          display: block;
          width: 100%;
        }
      }

    .newitem-title{
      font-family: Roboto Mono;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      text-transform: uppercase;
      color: #273364;
      padding-top: 1rem;
      text-align: center;
    }

    // .newitem{
    .newitem-photo{
      img{
        display: block;
        width: 100%;
        // height:20vh;
        object-fit: cover;
        margin: auto;
      }
    }

      

    .newitem-summary{
      font-family: Noto Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #828282;
      padding-top: 1rem;
    }

    .newitem-readmore{
      /* Read more */
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-transform: uppercase;
      color: #273364;
      float:right;
      display: inline-block;
      width: 50%;
      position: relative;
      padding-top: 1rem;
    }


    .newitem-readmore:before {
      /*
      content: "";
      height: 2px;
      border-top: 2px solid #273364;
      width: 110%;
      display: inline-block;
      margin: 20px;
      float: left;
      position: relative;
      left: -100%;*/
      content: '';
      border-bottom: solid 2px #273364;
      position: absolute;
      width: 80%;
      top: 1.5rem;
      right:70%;
      margin-right:5rem;
      z-index: 1;
      // width: 100%;
      // right: 90%;
      // top: 50%;
      //margin-right: 2rem;
    }

    // }


  }


  ul.pager {
    list-style-type: none;
    text-align: center;
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 100%;


    .pager__item {

      text-align: center;
      a {
        text-transform: uppercase;
        color: #BDBDBD;
      }
    }
  }

}
