.hero{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Noto Sans';
    font-weight: 400;
    color: #353535;
    position: relative;
padding: 0.5rem 0;

    .text{
      display: inline-block;
      font-size: 2.8rem;
      line-height: 1.405;
    }

    .text__first, .text__second{
      position: relative;
    }

.text__word{
    opacity: 0;

    &-highlighted {
    font-weight: 900;
    color: #fff;
    background: #8f7229;
    padding: 0 1rem;
    }
}

.text__first-bg, .text__second-bg{
    display: block;
      width: 100%;
        height: 100%;
          position: absolute;
            left: 0;
              top: 0;
                z-index: 100;
                  transform-origin: left;
                    transform: scaleX(0);

}

.text__first-bg{
    background-color: #8f7229;

}

.text__second-bg{
    background-color: #f5f5f5;

}

.text__second{
    margin-left: 15vmin;

}

.restart{
    position: relative;
      font-size: 15px;
        text-align: center;

}
}
