.page-node-11 {
   .breadcrumbs {
     display: none;

   }
 }

.reservations {

   &__info {
     text-transform: uppercase;
   }

   &__title {
     font-weight: 300;
     text-transform: uppercase;
     color: #000;
   }

   &__subtitle {
     text-transform: uppercase;
     color: $light-gray;

     @include breakpoint(small only){
        align-self: center;
        position: absolute;
        right: 0;
     }

     &::after {
       @include breakpoint(small only){
        display:none;
       }
     }
   }

   &__text {
     color: $light-gray;
   }

   &--item {
     padding-bottom: 2rem;

     @include breakpoint(small only){
       padding-bottom:0;
       border-top: 1px solid #AEAEAE;

       &:last-child {
         @include breakpoint(small only){
           padding-bottom:0;
           border-top: 1px solid #AEAEAE;
           border-bottom: 1px solid #AEAEAE;
       }
     }

     &--inverse {
       flex-direction: row-reverse;
       padding-bottom: 2rem;
     }
   }
  }


 }

.views-row-even .reservations__item {
     flex-direction: row-reverse;
     padding-bottom: 2rem;

     @include breakpoint(small only){
       padding-bottom:0;
      }
}
.reservations-view .views-row-odd .reservations__image {
     float:right;
}

.is-accordion-submenu-parent > a::after {
  display: none;
}


.page-node-11 .field-name-field-file-image-title-text {
  text-transform: uppercase;
  margin: 2rem;
  text-align: center;
  font-size: 1.2rem;
}

.page-node-11 form {
    text-align: center;

    .webform-component-node-reference {
      select {
        margin-bottom: 0;
      }
    }
    .webform-component-select {
      select {
        margin-bottom: 0;
      }
    }
}
.page-node-11 .reservations-fieldset > .fieldset-wrapper {
  @include flex-grid-row;
}
