
.block-facetapi {

  .block-title {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 1rem 0;
    font-size: 1.2rem;
    line-height: 1;
    text-align: left;
    width: 100%;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #444;
  }

  .item-list {
    ul {
      list-style: none;
    }
    ul li {
      font-size: 1rem;
      vertical-align: middle;
      text-align: left;
    }
  }



  a{
    font-size: 1rem;
    vertical-align: middle;
    text-align: center;
    color:$black;
  }


  label{
    color:#444;
    font-size: 1rem;
    vertical-align: middle;
    text-align: center;
  }


  /*

  input[type="checkbox"] {
    display:none;
  }
  input[type="checkbox"] + a {
    display:inline-block;
    width:19px;
    height:19px;
    margin:-2px 10px 0 0;
    vertical-align:middle;
    //http://stonecrete.docker.localhost/sites/all/themes/custom/foundation/src/assets/img/check_radio_sheet.png
    background:url("http://stonecrete.docker.localhost/sites/all/themes/custom/foundation/src/assets/img/check_radio_sheet.png") left top no-repeat;
    cursor:pointer;
  }
  input[type="checkbox"]:checked + a {
    background:url("http://stonecrete.docker.localhost/sites/all/themes/custom/foundation/src/assets/img/check_radio_sheet.png") -19px top no-repeat;
  }

*/


    input[type="checkbox"] {
      width: 1.3em;
      height: 1.3em;
      background-color: white;
      vertical-align: middle;
      border: 3px solid #ddd;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
      margin-top: 7px;
      margin-right: 5px;
    }

    input[type="checkbox"]:checked {
      width: 1.3em;
      height: 1.3em;
      background-color: #444;
      margin-top: 7px;
      margin-right: 5px;
      border: 3px solid #ddd;
    }

/*
    input[type="checkbox"]{
      position:absolute; z-index:-1000; left:-1000px; overflow: hidden; clip: rect(0 0 0 0); height:1px; width:1px; margin:-1px; padding:0; border:0;
    }

    input[type="checkbox"] + label {
      padding-left:55px;
      height:50px;
      display:inline-block;
      line-height:50px;
      background-repeat:no-repeat;
      background-position: 0 0;
      font-size:50px;
      vertical-align:middle;
      cursor:pointer;

    }

    input[type="checkbox"]:checked + label{
      background-position: 0 -50px;
    }
    label {
      background-image:url(sites/all/themes/custom/foundation/src/assets/img/checkboxes.png);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }


*/

}
