
.card{
  border:1px solid transparent;
}

.card-tabs {
  border:1px solid transparent;
  .card-divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding: 15px 20px;
    //.card-divider {
    background: none;
    border-bottom: 2px solid #373737;
    padding: 0px!important;
    //}
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }

    .fa {
      position: relative;
      margin-right: 0;
    }
  }

  .tabs {
    padding-top: 4px;
    /*border: 0px solid #cacaca;*/
    border: 0px;
  }

  .tabs,
  .tabs a {
    background:none;
  }

  .tabs a {
    padding: 0;
    margin: 0 10px;
    font-size: 13px;
    color: lighten($body-font-color, 50%);
  }

  .tabs a:hover,
  .tabs .is-active a {
    color: $body-font-color;
  }

  .tabs-panel {
    padding: 0;
  }

  .tabs-content{
    border:0px solid transparent;
  }


  .tabs-title a{
    display: block;
    padding: 10px;
    font-size:1.1rem;
    font-weight: normal;
    text-transform: uppercase;
  }

  .tabs-title .is-active {
    font-size:1.1rem;
    font-weight: bolder;

  }

  .is-active {
    position: relative;
  }

  .tabs-title.is-active::after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #373737;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    bottom:-11px;
    position: absolute;
    left: 50%;
    margin-left: -5px;
  }


}




