$newsletter-bg: #E6EFF7;
$newsletter-button-width: 25vw; // % of wiewport width

.newsletter {
  background-color: $newsletter-bg;
  padding: 0 1rem;

  .input-group-field {
    border-radius: 0;
    position: relative;
    transform: translateX(0.5rem);
  }

  .input-group {
    margin-bottom: 0;
  }

  .newsletter-inner {
    text-align: center;
  }

  .input-group-button .button{
    border-radius: 0;
    position: relative;
    transform: translateX(-0.5rem);
    min-width: $newsletter-button-width;
  }

  @include breakpoint(medium) {
    padding: 0 3rem;
  }

  @include breakpoint(large) {
    padding: 2rem 6rem;
  }
}
