.view-applications-listing-using-facets {
  .views-row{
  padding-bottom: 20px;
  }

  .product_title{
    font-size:1.5rem;
    font-weight:bold;
    a{
    color: $black;
    }
  }

  .product_description{
    font-size:1rem;
    text-align:justify;
  }


  .product_description{
    font-size:1rem;
  }

  .product_footer{
    .mainText a{
     color: #8F7229;
      font-size:1.3rem;
      font-weight:bold;

    }
  }

}

