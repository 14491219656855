
.contact-us-form {
  padding: 20px 20px 20px 20px;


  .form-item, .form-actions{
    margin-top: 0em;
    margin-bottom: 0em;
  }

  input[type=text],
  input[type=email],
  textarea {
    // margin-bottom: 1.5rem;
  }
// http://foundation.zurb.com/building-blocks/blocks/contact-us-section.html
  $contact-us-section-padding: 1rem;
  $contact-us-section-bg: $body-background;

  .webform-client-form{
    margin: 0 auto;
  }

  .webform-client-form div {
    justify-content: center;
    @include flex-grid-row();
  }
  .button.secondary{
    background-color: #8f7229;
    color: #fefefe;
  }

  .button.secondary:hover, .button.secondary:focus {
    background-color: #5e5e5e;
    color: #fefefe;
  }

  .webform-submit {
    @include flex-grid-column($columns:4);
    //@include grid-column($offset:3);
    @include breakpoint(small only) {
      @include flex-grid-column($columns:12);
    }
  }

  .webform-component{
    @include flex-grid-column($columns:4);
    @include breakpoint(small only) {
      @include flex-grid-column($columns:12);
    }
  }
  .webform-component-textarea{
    @include flex-grid-column($columns:12);
    min-height:150px;
  }

  .block-title{
    font-size:1.5rem;
  }

  .contact-us-section {
    padding: $contact-us-section-padding;
    background: $contact-us-section-bg;

  }


}




.contact-us-list {
  list-style-type: none;
  margin: 0;

  li {
    a {
      color: $black;
    }
  }

  li::before {
    margin-right: 2rem;
    font-size: 2rem;
    vertical-align: middle;
    color: $dark-gray;
    font-family: 'FontAwesome';
  }

  // generates icons - change fontawesome content codes below to change icons
  .address::before {
    content: '\f041';
    margin-left: 0.4rem;
    margin-right: 2.3rem;
  }

  .email::before {
    content: '\f003';
  }

  .phone::before {
    content: '\f098';
  }
}

