.view-faq {
  .view-content{
    justify-content: center;
    @include flex-grid-row();
  }

  .views-row{
    @include flex-grid-column($columns:6);
    @include breakpoint(small only) {
      @include flex-grid-column($columns:12);
    }
  }



}


a.faq-accordion {
  // background-color: #eee;
  background-color:#8f7229;
  color: white;

  cursor: pointer;

  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;

  display: inline-block;
  padding: 10px;
   margin: 0;
  // font-weight: 700;
  font-size: 1.4rem;
  line-height: 40px;
  // margin-bottom:10px;
}

a.faq-accordion.active {
  background-color:#8f7229;
  color: white;
}
a.faq-accordion:hover {
  background-color: #c0a354; //
  color: white;
}

div.faq-accordion-panel:not(.active){
  padding-top:0px;
  padding-bottom: 0px;
}

div.faq-accordion-panel.active{
  padding-top: 10px;
  padding-bottom: 10px;
}
div.faq-accordion-panel {
  background-color: white;
  padding: 10px 10px 10px 10px;
  margin: 0;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-weight: 700;
  font-size: 1rem;
  display: block;
  box-sizing: border-box;
  width: 100%;

  text-justify: distribute;
  border:1px solid #8f7229;
}