.lang-select {
  display: flex;
  align-items: left;

  @include breakpoint(large) {
    align-items: center;
  }

}

.block-locale{
  @include breakpoint(small only){
    text-align: left;
  }
}
.block-locale  .contextual-links-region .contextual-links-wrapper {
   //  padding-right: 50px;
  //  margin-right: 20px;
  }

.block-locale {
  display:inline-block;
  width:100%;
  /*
  margin-left:20px;
  margin-right:20px;

  padding-left:50px;
  padding-right:50px;
  */
  text-align: right;

  .contextual-links-region{
    width:100%;
  }
  .language-switcher-locale-url{
    font-size: 1rem;
    font-weight: bold;
    color: #4F4F4F;
    text-transform: uppercase;
    display: inline;
    margin:0;
  }

  .language-switcher-locale-url ul{
    list-style: none;
    display: inline;
    float: left;
  }

  .language-switcher-locale-url li{
    display: inline;

  }

  .language-switcher-locale-url li + li::before{
    content: " | ";
    vertical-align: middle;
    font-weight:normal;
  }

  .language-link {
    display: inline;
    font-weight: normal;
    color: #4F4F4F;
    text-transform: lowercase;
  }

  .language-link.active{
    color:white;
    color: #4F4F4F;
    font-weight: bold;
    text-decoration: underline;
  }



  }

