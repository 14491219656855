
.view-clone-of-list-of-applications-with-images {
  //same as view-list-of-product-types-with-images

  .view-content {
    display: flex;
    flex-wrap: wrap;
    margin:2px 2px 2px 2px;
  }

  .rowClass{
    display: inline-block;
    margin-bottom: 0px;
    width: calc(50% - 4px);
    margin-right: 8px;
    text-decoration: none;
    color: $black;

    border:1px solid $black;
  }

  .divName  a,a:hover, a:focus{
      font-size: 1rem;
      /*font-weight:bold;*/
      color: $black;
      text-align:center;
      text-decoration: underline;
  }

    .rowClass:nth-of-type(2n) {
    margin-right: 0;
  }

  @media screen and (min-width: 50em) {
    .rowClass{
      width: calc(25% - 6px);
    }

    .rowClass:nth-of-type(2n) {
      margin-right: 8px;
    }

    .rowClass:nth-of-type(4n) {
      margin-right: 0;
    }
  }



  .divName  {
    padding-top: 5px;
    padding-left: 5px;
    background-color: $white;

    min-height: 50px;
  }

  .DivPhoto  {
    margin: 0;
    overflow: hidden;
  }

  .DivPhoto a:hover img {
    transform: scale(1.15);
  }

  .DivPhoto img {
    border: none;
  /*
    max-width: 100%;
    height: auto;
     */
    display: block;
    background: #ccc;
    transition: transform .2s ease-in-out;
    justify-content:center;
    width: 100%;
    min-height:250px;
    max-height:250px;
    object-fit: cover;
  }


}
