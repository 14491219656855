.products-front {
  text-align: center;

  h2 {
    font-size: 1.2rem;
    padding: 0 3rem;
  }

  p {
    font-size: 0.9rem;
    padding: 0 3rem;
    color: #8f7229;
  }
}


.aboutFront {
  .topHeading {
    font-weight: 600;
    color: #8F7229;
  }

  .head {
    font-size:1.1rem;
    font-weight: 600;
  }

  .cert-link {
    color: #4d4d4d;
   //  font-weight: 600;
  }

  .read-front {
    padding-top: 2rem;

    img {
      width: 100%;
    }
  }
}

.about-us-block {
  background: #f5f5f5;
  padding: 1rem;
}
