
.view-list-of-product-types-with-images{


  /*
 <div class="headerContainer">
<a href="#" class="customButton">
<span class="smallerText">&gt;</span>
<span class="mainText">Κατάλογοι</span>
<span class="smallerText">&lt; </span>
</a>
</div>

  */

  .view-header{
    text-align: center;
    vertical-align: middle;
    .headerContainer{
      text-align: center;
      vertical-align: middle;
      padding-bottom:10px;
    }
    .primary {

    }
    .customButton{
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      padding: 0.85em 1em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      background-color:#B9A05E;
      border-radius: 25px;
      font-size: 1rem;
      line-height: 1;
      text-align: center;
      padding-left:60px;
      padding-right:60px;

    }

    .button{
      margin:0 auto;
    }
    .mainText{
      color:$white;
      font-size:2rem;
      text-transform: uppercase;
    }
    .smallerText{
      color:$white;
      font-size:1rem;
      }

  }


    .view-content {
      display: flex;
      flex-wrap: wrap;
    }

    .rowClass{
      display: inline-block;
      margin-bottom: 0px;
      width: calc(50% - 4px);
      margin-right: 8px;
      text-decoration: none;
      color: $black;
    }

    .divName  a,a:hover, a:focus{
      font-size: 1rem;
      /*font-weight:bold;*/
      color: $black;
      text-align:center;
      text-decoration: none;
    }

    .rowClass:nth-of-type(2n) {
      margin-right: 0;
    }

    @media screen and (min-width: 50em) {
      .rowClass{
        width: calc(25% - 6px);
      }

      .rowClass:nth-of-type(2n) {
        margin-right: 8px;
      }

      .rowClass:nth-of-type(4n) {
        margin-right: 0;
      }
    }



    .divName  {
      padding-top: 5px;
      padding-left: 5px;
      background-color: #E7E6EB;
      min-height: 50px;
    }


    .DivPhoto  {
      margin: 0;
      overflow: hidden;

    }

    .DivPhoto a:hover img {
      transform: scale(1.15);
    }

    .DivPhoto img {
      border: none;
      /*
      max-width: 100%;
      height: auto;
      */
      display: block;
      background: #ccc;
      transition: transform .2s ease-in-out;
      justify-content:center;
      width: 100%;
      object-fit: cover;
      min-height:150px;
      max-height:150px;
    }



    /*
      .row-1{
      background-color: #E7E6EB;
      }


      .views-field-name a,a:hover, a:focus{
        font-size: 0.9rem;
        font-weight:bold;
        color: $black;
        text-align:center;
        vertical-align: top;
      }


      .field-field-presentation-photo img{
        width:100%;
      }


    */
    /*
      .view.view-list-of-product-types-with-images td
      {
        position: relative;
      }

      span.views-field.views-field-field-presentation-photo {
        display: block;
        width: 100%;
        height: 100%;
      }

      span.views-field.views-field-name {
        position: absolute;
        top:0;
        background-color: #E7E6EB;
        width: 100%;

      }
      */
  }
