.view-applications-list-view {
    padding:10px;
    .view-content {
      @include flex-grid-row($size: $grid-row-width);

      h3{
        display: block;
      }
      
      .views-row {
        @include flex-grid-column($columns:5);
      }

      @include breakpoint(small only) {
        .views-row {
          @include flex-grid-column($columns: 12);
        }
      }
    }

  a,a:hover,a:visited,a:active {
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size:1.4rem;
    font-weight: 700;
    width: 100%;
  }

  .views-row {
    background-color:#eadec4;
    color: black;
  }

  .views-row:hover {
    background-color: #c0a354; // #8f7229
    color: black;
  }

  .views-row {
    margin:10px;
    @include flex-grid-row();
  }
  .views-field-name-field{
    @include flex-grid-column($columns: 12);
    padding-top:10px;
    padding-bottom:10px;

  }

.views-field-field-photo{
  text-align: center;
  @include flex-grid-column($columns: 3);
  padding-bottom:20px;
}
  .views-field-description-field-et{
    font-size:0.7rem;
    @include flex-grid-column($columns: 9);
  }

  .views-field-name-i18n{
    width: 100%;
  }
}